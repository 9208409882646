import { toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

const useGetRules: Boolean | Object = (props: { min: Number; max: Number; required: Boolean }) => {
  const { t } = useI18n({ useScope: 'global' });

  const { min, max, required } = toRefs(props);

  const rules = (value: string | string[]) => {
    if (required && Boolean(required.value)) {
      if (!value) {
        return t('form.error.field_required');
      }
      if (Array.isArray(value) && (value as string[]).length == 0) {
        return t('form.error.field_required');
      }
    }
    if (typeof value === 'string') {
      if (min && Boolean(min.value) && parseInt(value) < min.value) {
        return t('form.error.min_value', {
          min: min.value.toLocaleString('fr-FR')
        });
      }
      if (max && Boolean(max.value) && parseInt(value) > max.value) {
        return t('form.error.max_value', {
          max: max.value.toLocaleString('fr-FR')
        });
      }
    }
    return true;
  };
  return {
    rules
  };
};
export default useGetRules;
