<template>
  <va-inner-loading :loading="loading" color="primary">
    <form ref="recover-email" class="login">
      <div>
        <p>{{ $t('user.recover_password_txt') }}:</p>
        <va-input
          class="mb-3 mt-4"
          v-model="email"
          type="email"
          :label="$t('user.form.email')"
          :rules="[useGetRules({ required: true }).rules]"
        />
      </div>
      <div class="row justify--center">
        <va-button color="secondary" @click="submitForm" class="my-0">{{ $t('user.reset_password') }}</va-button>
      </div>
    </form>
  </va-inner-loading>
</template>

<script>
import { SEND_EMAIL_RESET_PASSWORD } from '@mdb/core';
import useGetRules from '@/components/form/useGetRules';
import { useRouter, useRoute } from 'vue-router';
import { useFormMutation } from '@/components/helpers/useFormMutation';
import { ref } from 'vue';
export default {
  name: 'recover-password',
  setup: () => {
    const form = ref('form');
    const email = ref('');
    const router = useRouter();
    const { loading, onDone, mutate } = useFormMutation(SEND_EMAIL_RESET_PASSWORD);

    onDone(() => {
      router.push({ name: 'login' });
    });
    const submitForm = async () => {
      await mutate({ email: email.value });
    };
    return {
      email,
      submitForm,
      useGetRules,
      loading
    };
  }
};
</script>

<style lang="scss"></style>
